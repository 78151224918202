@font-face {
  font-family: BebasNeue;
  src: url('/public/BebasNeue-Regular.woff2')
}

#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 1em;
}

/* Track */
.dark ::-webkit-scrollbar-track {
  background: #212121;
}
.light ::-webkit-scrollbar-track {
  background: #e9e9e9;
}

/* Handle */
.dark ::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border-radius: 1em;
}
.light ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 1em;
}

/* Handle on hover */
.dark ::-webkit-scrollbar-thumb:hover {
  background: #6f6f6f;
  cursor: grabbing;
}
.light ::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  cursor: grabbing;
}

.dark .moz-scrollbar {
  scrollbar-color: #3e3e3e #212121;
  scrollbar-width: thin;
}
.light .moz-scrollbar {
  scrollbar-color: #ccc #e9e9e9;
  scrollbar-width: thin;
}


.lehmoflix-logo {
  font-size: 32px;
  color: #fff;
  font-family: "BebasNeue";
  text-decoration: none;
}

.lehmoflix-logo.big {
  font-size: 48px;
}
.lehmoflix-logo.red {
  color: #d90808;
}

.lehmoflix-video-card:hover {
  cursor: pointer;
}

.lehmoflix-player-content {
  flex: 2;
  min-width: 350px;
  padding: 32px 0 0 32px;
}
.lehmoflix-recent {
  flex: 1;
  padding: 32px;
  min-width: 350px;
}

.dark .lehmoflix-player-description,
.dark .lehmoflix-player-description * {
  color: #ccc;
  margin: 0;
}
.light .lehmoflix-player-description,
.light .lehmoflix-player-description * {
  color: #212121;
  margin: 0;
}

@media (max-width: 764px) {
  .lehmoflix-player-content {
    flex: 2;
    width: 100%;
    padding: 16px 32px 0 32px;
  }
  .lehmoflix-recent {
    width: 100%;
    padding: 0 32px 0 32px;
  }
}

.not-loading.dark {
  background: #272727;
}
.not-loading.light {
  background: #d90808;
}

.scroll-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 2;
}

.light .scroll-overlay.left {
  width: 10px;
  background: linear-gradient(90deg, #e9e9e9, transparent);
  left: 0;
}
.light .scroll-overlay.right {
  width: 50px;
  background: linear-gradient(90deg, transparent, #e9e9e9);
  right: 0;
}

.dark .scroll-overlay.left {
  width: 10px;
  background: linear-gradient(90deg, #212121, transparent);
  left: 0;
}
.dark .scroll-overlay.right {
  width: 50px;
  background: linear-gradient(90deg, transparent, #212121);
  right: 0;
}
/*.video-js, .lehmoflix-player, .vjs-tech {
  transition: height 2s;
}*/

.lehmoflix-player.light .video-js .vjs-control-bar,
.lehmoflix-player.light .video-js .vjs-big-play-button,
.lehmoflix-player.light .video-js .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background: linear-gradient( transparent 30%, #e9e9e9 100%);
}

.lehmoflix-player.dark .video-js .vjs-control-bar,
.lehmoflix-player.dark .video-js .vjs-big-play-button,
.lehmoflix-player.dark .video-js.dark-mode .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background: linear-gradient( transparent 30%, #212121 100%);
}
.video-js .vjs-big-play-button {
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
}
.vjs-button > .vjs-icon-placeholder:before, .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
  border-color: #fff;
  background-color: rgba(217, 8, 8, 0.73);
  transition: all 0s;
}

.lehmoflix-player .vjs-button > .vjs-icon-placeholder {
  font-size: 1.5em;
  color: #fff;
}
.video-js .vjs-control-bar {
  height: 5em;
}
.video-js .vjs-control {
  align-items: center;
  justify-content: center;
}
.video-js .vjs-slider {
  background: #9c9c9c;
}
.video-js .vjs-play-progress {
  background: #d90808;
}
.video-js .vjs-load-progress {
  background: #bbbbbb;
}
.video-js .vjs-load-progress div {
  background: #bbbbbb;
}
.video-js .vjs-time-control {
  font-size: 1.5em;
}
.vjs-button > .vjs-icon-placeholder:before {
  font-size: 1.8em;
  line-height: normal;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lehmoflix-player .vjs-remaining-time {
  display: flex;
  color: #fff;
}
.video-js .vjs-progress-holder {
  height: 0.4em;
}
.vjs-playback-rate .vjs-playback-rate-value {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-js .vjs-live-display {
  color: #d90808;
  font-size: 30px;
  font-family: BebasNeue;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.lehmoflix-video-cover-wrapper {
  position: relative;
  overflow: hidden;
}

.lehmoflix-video-cover {
  /*position: absolute;*/
  margin-left: calc(-20% - 40px);
  width: 100%;
  min-width: 125px;
  min-height: 200px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 1s;
  animation: slide 0.5s forwards;
  animation-delay: 1s;
}

@-webkit-keyframes slide {
  100% { margin-left: 0; }
}

@keyframes slide {
  100% { margin-left: 0; }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
